import * as React from "react";
import { Grid, Row, Col } from "rsuite";
import HeaderLarge from "components/section/headerLarge";
import LayoutPatient from "components/layout/patient";
import SectionWrapper from "components/section/sectionWrapper";
import ContactFormPatient from "components/ui/contactFormPatient";

const ContactPage = () => {
  return (
    <LayoutPatient>
      <HeaderLarge title="Contact Patiënt" description="Mist u informatie? Staat uw vraag er niet tussen? We horen dit graag. U kunt onderstaand contactformulier invullen. Zo kunnen we het kennisplatform zo compleet mogelijk maken. Let op: wij kunnen geen antwoord geven op persoonlijke medische vragen. Daarvoor kunt u contact opnemen met uw huisarts of behandelend specialist." />
      <div style={{ backgroundColor: "#F6F4F5" }}>
        <SectionWrapper padded>
          <Grid>
            <Row>
              <Col>
                <ContactFormPatient />
              </Col>
            </Row>
          </Grid>
        </SectionWrapper>
      </div>
    </LayoutPatient>
  );
};

export default ContactPage;
